import {SubmissionScanImageType} from './general.symbols';
import {User} from '../../auth/symbols';


/** Describes available frontend-related submission types. */
export enum submissionType {
  Public = 'public',
  Private = 'private',
  Shared = 'shared',
  Static = 'static',
}

export interface LabelData {
  volume: number;
  value: number;
}

export interface Labels {
  [key: string]: LabelData;
}

export interface Surface {
  id: number;
  label_value: number[];
}

export interface Submission {
  corrected_image?: number;
  surface_set?: Surface[];
  base_surface?: number;
  left_ct_processed?: number;
  right_ct_processed?: number;
  left_ct_harmonized?: number;
  right_ct_harmonized?: number;
  scan_date?: string;
  person_weight_unit?: number;
  person_weight?: number;
  person_height_unit?: number;
  person_height1?: number;
  person_height2?: number;
  generate_gif?: boolean;
  reason?: string;
  display_name?: string;
  is_public?: boolean;
  brain_age_derivative?: number;
  id?: string;
  base_image?: number;
  processed_image?: number;
  harmonized_image?: number;

  /** Contain related to submission scan general status. */
  status?: SubmissionGeneralStatus;

  /** Contain related to submission scan detailed status. */
  status_description?: SubmissionDetailedStatus;
  user?: User['uid'];
  age_at_scan?: number;

  /** Indicates if the main BrainKey PDF report is generated and can be viewed. */
  pdf_ready?: boolean;

  /** Indicates if KeyLayer PDF report is generated and can be viewed. */
  key_layer_ready?: boolean;

  /** Indicates if a Executive PDF report is generated and can be viewed. */
  executive_ready?: boolean;

  /** Indicates if a Hyperintensities PDF report is generated and can be viewed. */
  white_matter_ready?: boolean;

  /** Indicates if a Perivascular Spaces PDF report is generated and can be viewed. */
  pvs_ready?: boolean;

  /** Indicates if a Submission Summary PDF report is generated and can be viewed. */
  submission_summary_ready?: boolean;

  /**
   * Indicates if "All Reports" PDF report is generated and can be viewed.
   * "All Reports" - just a name of the report that contains four reports: MRI, KeyLayer, Hyperintensities, Perivascular Spaces.
   */
  all_reports_ready?: boolean;

  /** Indicates if the expanded ventricle results report is generated and can be viewed. */
  evr_ready?: boolean;

  /**
   * Contains is approved statuses for related scan images.
   */
  related_scans_approved?: SubmissionRelatedScans;

  /**
   * Contains a related key layer scan version.
   */
  version?: SubmissionKeyLayerScanVersion['id'];

  /**
   * Contains the id of the derivative used for the AR image.
   */
  ar_image?: number;

  results?: SubmissionResult[];
  is_shared?: boolean;
  results_inspected?: boolean;
  is_owner_charged?: boolean;
  scan_quality?: number;
  contrast?: boolean;
  sex?: SubmissionSex;
  data?: {user_metadata?: {scan_type?: string}};
}

/**
 * Describes a possible statuses for the submission.
 */
export enum SubmissionGeneralStatus {
  /** Submission is uploaded and is somewhere before the applying analysis (file type transformation, queue, etc.). */
  new = 1,

  /** Submission is in the processing - analysis is running. */
  processing = 2,

  /** Submission is processed, approved by editors (or automatically) and ready for the review. */
  approved = 3,

  /** Some error occurred during the submission processing. */
  error = 4,
}

/**
 * Describes a possible statuses for the submission.
 * Provides more detailed statuses than the general statuses.
 * Detailed status always starts with the general status.
 */
export enum SubmissionDetailedStatus {
  /** Unexpected error which cannot be classified occurred during the submission processing. */
  errorUnknown = 41,

  /** Error occurred in runners. */
  errorInRunners = 42,

  /** Error occurred in processing and processing was halted. */
  errorProcessingHalted = 43,

  /** The submission was rejected because of the low quality. */
  errorRejectedByQuality = 44,

  /** The submission was rejected because of the wrong format. */
  errorRejectedByFormat = 45,

  /** The submission was rejected, but no reason provided. */
  errorRejectedByUnknownReason = 46,
}

/**
 * Describes a possible options for the submission sex field.
 */
export enum SubmissionSex {
  Male = 'M',
  Female = 'F',
}

/**
 * Describes a statuses format for the related scan images.
 */
export interface SubmissionRelatedScans {
  /** Contains ids of scans for the T2 scan. */
  T2: SubmissionRelatedScanIds;

  /** Contains ids of scans for the Flair scan. */
  Flair: SubmissionRelatedScanIds;

  /** Contains ids of scans for the Flair scan. */
  TOF: SubmissionRelatedScanIds;
}

/**
 * Describes a statuses format for the related scan images.
 */
export interface SubmissionRelatedScanIds {
  /** Contains id of the base scan image (non-processed). */
  base: number;

  /**
   * Contains id of the processed scan image (contains regions).
   * This scan image is the result of applying runners on the base scan image.
   * It is used for displaying selected regions (labels) on the scan image view.
   * Should be used only if the corrected scan image is not presented.
   */
  processed: number;

  /**
   * Contains id of the corrected scan image (contains regions).
   * This scan image is the result of the manual processed scan image editing.
   * It is used for displaying selected regions (labels) on the scan image view.
   * Should have the higher priority than the processed scan image.
   */
  corrected: number;
}

export interface SubmissionImageFile extends Blob {
  name: string;
  metadata?: SubmissionScanImageMetadata;
}

export interface SubmissionSurfaceFile extends Blob {
  name: string;
  labels: number[];
}

export interface SubmissionResult {
  id: number;
  is_report: boolean;
}

export const UNKNOWN_SCAN_TYPE_MARK = '|unknown|';

/** Define a submission type depend on provided data. */
export const defineSubmissionType = (submission: Submission): submissionType => {
  if (!submission?.is_public && submission?.is_shared) {
    return submissionType.Shared;
  } else if (submission?.is_public && !submission?.is_shared) {
    return submissionType.Public;
  }
  return submissionType.Private;
};

/**
 * Describes a submission key layer scan version metadata.
 */
export interface SubmissionKeyLayerScanVersion {
  /** Unique identifier of the key layer scan version. */
  id: number;
}

/**
 * Describes metadata fields for submission scan image.
 */
export interface SubmissionScanImageMetadata {
  /** Unique identifier of the related derivative. */
  id: number;

  /** Api for retrieving the image file. */
  apiEndpoint: string;

  /** Type of the scan image. */
  scanType: SubmissionScanImageType;

  /** Type of the derivative applied to the scan. */
  derivativeType: SubmissionScanImageDerivativeType;

  /** Name of the scan image. */
  name: string;
}

/**
 * Describes possible derivative types for the scan images.
 */
export enum SubmissionScanImageDerivativeType {
  /** Base scan image; no derivative applied. */
  base = 'base',

  /** Labeled scan image; derivative applied. */
  labeled = 'labeled',
}

/**
 * Describes property name for storing metadata in the Papaya viewer.
 * Used for understanding what scan images are presented in the papaya viewer.
 */
export const propertyNameForStoringMetadataInPapaya = '__metadata';

/**
 * Define metadata for the T1 base scan image presented in the submission.
 */
export const defineSubmissionT1BaseScanImageMetadata = (submission: Submission): SubmissionScanImageMetadata[] => {
  // Define scan part for the endpoint.
  const scanPart = submission?.is_public ? 'public_scan' : 'scan';

  // Define base T1 image metadata.
  // Consider it always presented, otherwise the whole submission will not be shown.
  const baseT1ImageMetadata: SubmissionScanImageMetadata = {
    id: submission.base_image,
    apiEndpoint: `/api/research/${scanPart}/show-decompressed/${submission.base_image}/`,
    scanType: SubmissionScanImageType.t1,
    derivativeType: SubmissionScanImageDerivativeType.base,
    name: `${SubmissionScanImageType.t1}_base`,
  };

  // Return the array of scan images metadata.
  return [baseT1ImageMetadata];
}

/**
 * Define metadata for the T1 label scan image presented in the submission.
 */
export const defineSubmissionT1LabelScanImageMetadata = (submission: Submission): SubmissionScanImageMetadata[] => {
  // Define scan part for the endpoint.
  const scanPart = submission?.is_public ? 'public_scan' : 'scan';

  // If the harmonized T1 image is presented, define its metadata.
  // Otherwise, try to define metadata for the processed T1 image.
  const labeledImage = submission.harmonized_image || submission.processed_image;
  const endPointParam = submission.harmonized_image ? 'harmonized' : 'processed';
  if (labeledImage) {
    const labeledT1ImageMetadata: SubmissionScanImageMetadata = {
      id: labeledImage,
      apiEndpoint: `/api/research/${scanPart}/show/${labeledImage}/${endPointParam}/`,
      scanType: SubmissionScanImageType.t1,
      derivativeType: SubmissionScanImageDerivativeType.labeled,
      name: `${SubmissionScanImageType.t1}_labeled`,
    };

    // Return the array of scan images metadata.
    return [labeledT1ImageMetadata];
  }

  return [];
}

/**
 * Define metadata for the T2 base scan image presented in the submission.
 */
export const defineSubmissionT2BaseScanImageMetadata = (submission: Submission): SubmissionScanImageMetadata[] => {
  // Define scan part for the endpoint.
  const scanPart = submission?.is_public ? 'public_scan' : 'scan';

  // Extract data for the T2 related scan images.
  const t2ImageRelatedData = submission.related_scans_approved?.T2;

  // If the T2 base image is presented, define its metadata.
  if (t2ImageRelatedData?.base) {
    const t2ImageMetadata: SubmissionScanImageMetadata = {
      id: t2ImageRelatedData.base,
      apiEndpoint: `/api/research/${scanPart}/show-decompressed/${t2ImageRelatedData.base}/`,
      scanType: SubmissionScanImageType.t2,
      derivativeType: SubmissionScanImageDerivativeType.base,
      name: `${SubmissionScanImageType.t2}_base`,
    };
    // Return the array of scan images metadata.
    return [t2ImageMetadata];
  }

  return [];
}

/**
 * Define metadata for the T2 label scan image presented in the submission.
 */
export const defineSubmissionT2LabelScanImageMetadata = (submission: Submission): SubmissionScanImageMetadata[] => {
  // Define scan part for the endpoint.
  const scanPart = submission?.is_public ? 'public_scan' : 'scan';

  // Extract data for the T2 related scan images.
  const t2ImageRelatedData = submission.related_scans_approved?.T2;

  // If the T2 base image is presented, define its metadata.
  if (t2ImageRelatedData?.base) {
    // If the corrected or processed T2 image is presented, define its metadata.
    const labeledImage = t2ImageRelatedData.corrected || t2ImageRelatedData.processed;

    // If the labeled T2 image is presented, define its metadata.
    if (labeledImage) {
      // Define endpoint parameter for the labeled T2 image.
      const endPointParam = t2ImageRelatedData.corrected ? 'corrected' : 'processed-simple';

      const labeledT2ImageMetadata: SubmissionScanImageMetadata = {
        id: labeledImage,
        apiEndpoint: `/api/research/${scanPart}/show/${labeledImage}/${endPointParam}/`,
        scanType: SubmissionScanImageType.t2,
        derivativeType: SubmissionScanImageDerivativeType.labeled,
        name: `${SubmissionScanImageType.t2}_labeled`,
      };

      // Return the array of scan images metadata.
      return [labeledT2ImageMetadata];
    }
  }

  return [];
}

/**
 * Define metadata for the Flair base scan image presented in the submission.
 */
export const defineSubmissionFlairBaseScanImageMetadata = (submission: Submission): SubmissionScanImageMetadata[] => {
  // Define scan part for the endpoint.
  const scanPart = submission?.is_public ? 'public_scan' : 'scan';

  // Extract data for the Flair-related scan images.
  const flairImageRelatedData = submission.related_scans_approved?.Flair;

  // If the Flair base image is presented, define its metadata.
  if (flairImageRelatedData?.base) {
    const flairImageMetadata: SubmissionScanImageMetadata = {
      id: flairImageRelatedData.base,
      apiEndpoint: `/api/research/${scanPart}/show-decompressed/${flairImageRelatedData.base}/`,
      scanType: SubmissionScanImageType.flair,
      derivativeType: SubmissionScanImageDerivativeType.base,
      name: `${SubmissionScanImageType.flair}_base`,
    };
    // Return the array of scan images metadata.
    return [flairImageMetadata];
  }

  return [];
}

/**
 * Define metadata for the Flair label scan image presented in the submission.
 */
export const defineSubmissionFlairLabelScanImageMetadata = (submission: Submission): SubmissionScanImageMetadata[] => {
  // Define scan part for the endpoint.
  const scanPart = submission?.is_public ? 'public_scan' : 'scan';

  // Extract data for the Flair-related scan images.
  const flairImageRelatedData = submission.related_scans_approved?.Flair;

  // If the Flair base image is presented, define its metadata.
  if (flairImageRelatedData?.base) {
    // If the corrected or processed Flair image is presented, define its metadata.
    const labeledImage = flairImageRelatedData.corrected || flairImageRelatedData.processed;

    // If the labeled Flair image is presented, define its metadata.
    if (labeledImage) {
      // Define endpoint parameter for the labeled Flair image.
      const endPointParam = flairImageRelatedData.corrected ? 'corrected' : 'processed-simple';

      const labeledFlairImageMetadata: SubmissionScanImageMetadata = {
        id: labeledImage,
        apiEndpoint: `/api/research/${scanPart}/show/${labeledImage}/${endPointParam}/`,
        scanType: SubmissionScanImageType.flair,
        derivativeType: SubmissionScanImageDerivativeType.labeled,
        name: `${SubmissionScanImageType.flair}_labeled`,
      };
      // Return the array of scan images metadata.
      return [labeledFlairImageMetadata];
    }
  }

  return [];
}

/**
 * Define metadata for the TOF base scan image presented in the submission.
 */
export const defineSubmissionTofBaseScanImageMetadata = (submission: Submission): SubmissionScanImageMetadata[] => {
  // Define scan part for the endpoint.
  const scanPart = submission?.is_public ? 'public_scan' : 'scan';

  // Extract data for the TOF-related scan images.
  const tofImageRelatedData = submission.related_scans_approved?.TOF;

  // If the TOF base image is presented, define its metadata.
  if (tofImageRelatedData?.base) {
    const tofImageMetadata: SubmissionScanImageMetadata = {
      id: tofImageRelatedData.base,
      apiEndpoint: `/api/research/${scanPart}/show-decompressed/${tofImageRelatedData.base}/`,
      scanType: SubmissionScanImageType.tof,
      derivativeType: SubmissionScanImageDerivativeType.base,
      name: `${SubmissionScanImageType.tof}_base`,
    };
    // Return the array of scan images metadata.
    return [tofImageMetadata];
  }

  return [];
}

/**
 * Define metadata for the TOF label scan image presented in the submission.
 */
export const defineSubmissionTofLabelScanImageMetadata = (submission: Submission): SubmissionScanImageMetadata[] => {
  // Define scan part for the endpoint.
  const scanPart = submission?.is_public ? 'public_scan' : 'scan';

  // Extract data for the TOF-related scan images.
  const tofImageRelatedData = submission.related_scans_approved?.TOF;

  // If the TOF base image is presented, define its metadata.
  if (tofImageRelatedData?.base) {
    // If the corrected or processed TOF image is presented, define its metadata.
    const labeledImage = tofImageRelatedData.corrected || tofImageRelatedData.processed;

    // If the labeled TOF image is presented, define its metadata.
    if (labeledImage) {
      // Define endpoint parameter for the labeled TOF image.
      const endPointParam = tofImageRelatedData.corrected ? 'corrected' : 'processed-simple';

      const labeledTofImageMetadata: SubmissionScanImageMetadata = {
        id: labeledImage,
        apiEndpoint: `/api/research/${scanPart}/show/${labeledImage}/${endPointParam}/`,
        scanType: SubmissionScanImageType.tof,
        derivativeType: SubmissionScanImageDerivativeType.labeled,
        name: `${SubmissionScanImageType.tof}_labeled`,
      };

      // Return the array of scan images metadata.
      return [labeledTofImageMetadata];
    }
  }

  return [];
}



