import {forkJoin} from 'rxjs';
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {has, isEmpty, isEqual} from 'lodash';
import {SubmissionsService} from './submissions.service';
import {
  FocusCustomViewerRegion,
  InitSubmissions,
  LoadBrainAge,
  LoadBrainAgeSuccess,
  LoadFail,
  LoadPrivateSubmissions,
  LoadPrivateSubmissionsSuccess,
  LoadPublicSubmissions,
  LoadPublicSubmissionsSuccess,
  LoadSubmission,
  LoadSubmissionFail,
  LoadSubmissionsSuccess,
  LoadSubmissionSuccess,
  LoadUserRelatedSubmissions,
  LoadUserRelatedSubmissionsFail,
  LoadUserRelatedSubmissionsSuccess,
  ReloadSubmissions,
  SaveSubmission,
  SaveSubmissionFail,
  SaveSubmissionSuccess,
  SelectSubmission,
  UpdateCustomViewerRegionList,
  UpdateSingleSubmission,
  UpdateSingleSubmissionFail,
  UpdateSingleSubmissionSuccess,
  UpdateSubmissions,
} from './submission.actions';
import {SUBMISSION_STATE_DEFAULT, SubmissionStateModel} from './submission.models';
import {SelectForSubmission} from '../person/person.actions';
import {Person, PROGRESS_STATUSES} from '../../symbols';
import {BackendError, ErrorMessage, SuccessMessage} from '../../../messages/messages.actions';
import {AuthState} from '../../../auth/states/auth/auth.state';
import {LoginSuccess, LogoutSuccess} from '../../../auth/states/auth/auth.actions';
import {SubmissionGeneralStatus, Submission, UNKNOWN_SCAN_TYPE_MARK} from '../../../dashboard/symbols/submission.symbols';
import {CustomRegion} from '../../../dashboard/symbols/scan-viewer.symbols';
import {SelectMainLevelBrainPart} from '../../../dashboard-brain-parts/states/dashboard-brain-parts/dashboard-brain-parts.actions';
import {ProfileState} from '../profile/profile.state';
import {UserSexCode} from '../../../dashboard/symbols/general.symbols';
import {getSexCode, getSubmissionSexCode} from '../../functions/utils';
import {User} from '../../../auth/symbols';

@State<SubmissionStateModel>({
  name: 'submissions',
  defaults: SUBMISSION_STATE_DEFAULT,
})
@Injectable()
export class SubmissionState {
  constructor(private store: Store, private submissionsService: SubmissionsService) {}

  /**
   * Mark empty scan type with unknown mark.
   * Replace underlines with spaces and capitalize all letters.
   */
  static formatSubmissionScanType(submission: Submission): Submission {
    const safeScanTypeString = typeof submission.data?.user_metadata?.scan_type === 'string' ? submission.data.user_metadata.scan_type : '';
    const formattedScanTypeString = safeScanTypeString.replace(new RegExp('_', 'g'), ' ').toUpperCase();
    return {
      ...submission,
      data: {
        user_metadata: {
          scan_type: formattedScanTypeString ? formattedScanTypeString : UNKNOWN_SCAN_TYPE_MARK,
        },
      },
    };
  }

  @Selector()
  static brainAge(state: SubmissionStateModel): number {
    return state.brainAge;
  }

  @Selector()
  static submissionList(state: SubmissionStateModel): Submission[] {
    return state.list.filter(id => state.submissions[id]).map(id => state.submissions[id]);
  }

  @Selector([SubmissionState.submissionList])
  static publicSubmissions(_: SubmissionStateModel, submissions: Submission[]): Submission[] {
    return submissions.filter(s => s.is_public);
  }

  @Selector([SubmissionState.publicSubmissions])
  static sortedPublicSubmissions(_: SubmissionStateModel, submissions: Submission[]): Submission[] {
    return submissions.sort((s1, s2) => (s1.age_at_scan || 0) - (s2.age_at_scan || 0));
  }

  @Selector([SubmissionState.submissionList])
  static privateSubmissions(_: SubmissionStateModel, submissions: Submission[]): Submission[] {
    return submissions.filter(s => !s.is_public && !s.is_shared);
  }

  @Selector([SubmissionState.privateSubmissions])
  static sortedPrivateSubmissions(_: SubmissionStateModel, submissions: Submission[]): Submission[] {
    return submissions.sort((s1, s2) => new Date(s2.scan_date).getTime() - new Date(s1.scan_date).getTime());
  }

  @Selector([SubmissionState.submissionList])
  static sharedSubmissions(_: SubmissionStateModel, submissions: Submission[]): Submission[] {
    return submissions.filter(s => !s.is_public && !!s.is_shared);
  }

  @Selector()
  static privateSubmissionsLoaded(state: SubmissionStateModel): boolean {
    return state.privateSubmissionsLoaded;
  }

  // Return those submissions that the user owns,
  // no matter if public or private.
  @Selector([SubmissionState.submissionList, AuthState.getUser, AuthState.isAuthenticated])
  static ownSubmissions(_: SubmissionStateModel, submissions: Submission[], user: User, isAuthenticated: boolean): Submission[] {
    return submissions.filter(s => isAuthenticated && s.user === user.uid);
  }

  @Selector([SubmissionState.submissionList, AuthState.getUser, AuthState.isAuthenticated])
  static hasOwnSubmissions(_: SubmissionStateModel, submissions: Submission[], user: User, isAuthenticated: boolean): boolean {
    if (!submissions || isEmpty(submissions)) {
      return null;
    }
    return isAuthenticated && submissions.filter(s => s.user === user.uid).length > 0;
  }

  @Selector()
  static selectedSubmission(state: SubmissionStateModel): Submission {
    return state.selected && state.submissions[state.selected] ? state.submissions[state.selected] : null;
  }

  @Selector([SubmissionState.selectedSubmission])
  static safeSelectedSubmission(_: SubmissionStateModel, submission: Submission): Submission {
    return Object.getOwnPropertyDescriptor(submission, 'status') ? submission : null;
  }

  @Selector([SubmissionState.selectedSubmission, ProfileState.person])
  static selectedSubmissionSex(_: SubmissionStateModel, submission: Submission, person: Person): UserSexCode {
    if (submission?.is_public) {
      return getSexCode(submission);
    } else if (submission?.is_shared || (person && submission?.user !== person.user && submission?.sex)) {
      return getSubmissionSexCode(submission);
    } else {
      return person && person.sex;
    }
  }

  @Selector()
  static customViewerRegion(state: SubmissionStateModel): CustomRegion {
    return state.customViewerRegion;
  }

  @Selector()
  static customViewerRegionList(state: SubmissionStateModel): CustomRegion[] {
    return state.customViewerRegionList;
  }

  @Selector()
  static loaded(state: SubmissionStateModel): boolean {
    return state.loaded;
  }

  @Selector()
  static loading(state: SubmissionStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static loadSubmissionProgress(state: SubmissionStateModel): SubmissionStateModel['loadSubmissionProgress'] {
    return state.loadSubmissionProgress;
  }

  @Selector()
  static publicSubmissionsLoaded(state: SubmissionStateModel): boolean {
    return state.publicSubmissionsLoaded;
  }

  @Selector()
  static publicSubmissionsList(state: SubmissionStateModel): Submission[] {
    return Object.values(state.submissions).filter(submission => submission?.is_public);
  }

  @Selector([SubmissionState.selectedSubmission])
  static relatedSubmissionScans(state: SubmissionStateModel, selectedSubmission: Submission): Submission[] {
    // Get all accepted submissions as an array.
    const submissionList =
      // Transform stored dictionary to the list.
      Object.keys(state.submissions)
        .map(key => state.submissions[key])

        // Keep only good condition submissions.
        .filter(
          submission =>
            // Submission is approved.
            submission.status === SubmissionGeneralStatus.approved &&
            // Submission is not contrast (not marked as wrongly processed).
            !submission.contrast &&
            // Submission is not marked as bad quality (1 - the best quality, 5 - the worst).
            submission.scan_quality <= 2
        );

    // Return the related submissions based on the selected submission type.
    if (selectedSubmission.is_public) {
      return submissionList.filter(submission => submission.is_public);
    } else if (selectedSubmission.is_shared) {
      return submissionList.filter(submission => submission.is_shared && !submission.is_public);
    } else {
      return submissionList.filter(submission => !submission.is_shared && !submission.is_public);
    }
  }

  @Selector([SubmissionState.selectedSubmission, SubmissionState.relatedSubmissionScans])
  static relatedScansByScanType(_: SubmissionStateModel, selectedSubmission: Submission, relatedSubmissions: Submission[]): Submission[] {
    // Update selected submission with a safe scan type.
    const safeSelectedSubmission = SubmissionState.formatSubmissionScanType(selectedSubmission);

    // No related scans => not initialized yet or all scans are low quality. Return only current submission.
    if (relatedSubmissions?.length <= 1) {
      return [safeSelectedSubmission];
    }

    // Rename empty scan types to safe string.
    const renamedEmptyTypes: Submission[] = relatedSubmissions.map(submission => SubmissionState.formatSubmissionScanType(submission));

    // Remove all scans with the same type as selected (selected will be added then as first scan in the list).
    const removeSelectedType = renamedEmptyTypes.filter(
      submission => submission.data.user_metadata.scan_type !== safeSelectedSubmission.data.user_metadata.scan_type
    );

    // Remove all scans with unknown type.
    const removeUnknownType = removeSelectedType.filter(submission => submission.data.user_metadata.scan_type !== UNKNOWN_SCAN_TYPE_MARK);

    // Split to different arrays by type of scans.
    const splitByType = {};
    removeUnknownType.forEach(sub => {
      if (!Array.isArray(splitByType[sub.data.user_metadata.scan_type])) {
        splitByType[sub.data.user_metadata.scan_type] = [sub];
      } else {
        splitByType[sub.data.user_metadata.scan_type].push(sub);
      }
    });

    // Take the most actual item for each type group.
    const uniqueSubs = Object.keys(splitByType).map(
      key => splitByType[key].sort((s1, s2) => new Date(s2.scan_date).getTime() - new Date(s1.scan_date).getTime())[0]
    );

    return [safeSelectedSubmission, ...uniqueSubs];
  }

  @Selector([SubmissionState.privateSubmissions])
  static userHasGoodQualityScan(state: SubmissionStateModel, submissions: Submission[]): boolean {
    if (!state.initialized) {
      return null;
    }

    if (!submissions || isEmpty(submissions)) {
      return false;
    }

    const approvedSubmissions = submissions.filter(sub => sub.status === SubmissionGeneralStatus.approved);

    if (approvedSubmissions) {
      return !!approvedSubmissions.find(submission => submission.scan_quality <= 2 && !submission.contrast);
    }

    return false;
  }

  @Selector([SubmissionState.privateSubmissions])
  static userHasSubmissionWithPdfReady(state: SubmissionStateModel, submissions: Submission[]): boolean {
    if (!state.initialized) {
      return null;
    }

    if (!submissions || isEmpty(submissions)) {
      return false;
    }

    const approvedSubmissions = submissions.filter(sub => sub.status === SubmissionGeneralStatus.approved);

    if (approvedSubmissions) {
      const pdfReadySubmission = approvedSubmissions.find(submission => submission.pdf_ready);
      return !!pdfReadySubmission;
    }

    return false;
  }

  @Selector()
  static updateSingleSubmissionProgress(state: SubmissionStateModel): SubmissionStateModel['updateSingleSubmissionProgress'] {
    return state.updateSingleSubmissionProgress;
  }

  @Selector([AuthState.getUser, SubmissionState.selectedSubmission])
  static userHasAccessToProcessedData(_: SubmissionStateModel, user: User, selectedSubmission: Submission): boolean {
    return selectedSubmission?.is_public || selectedSubmission?.is_shared || user?.is_charged;
  }

  @Selector([SubmissionState.selectedSubmission])
  static isSelectedSubmissionGoodQuality(_: SubmissionStateModel, selectedSubmission: Submission): boolean {
    return selectedSubmission?.scan_quality <= 2 && !selectedSubmission?.contrast;
  }

  /**
   * We usually have data for 5..96 years old.
   * But the number of scans for 5..9 and 91..96 years old are very low.
   * It is not considered reliable to show the brain age for these age groups.
   */
  @Selector([SubmissionState.selectedSubmission])
  static isSelectedSubmissionInReliableAgeRange(_: SubmissionStateModel, selectedSubmission: Submission): boolean {
    return selectedSubmission?.age_at_scan >= 10 && selectedSubmission?.age_at_scan <= 90;
  }

  @Action(InitSubmissions)
  initSubmissions({getState, patchState, dispatch}: StateContext<SubmissionStateModel>): void {
    // We always include the static submission
    const state = getState();
    if (
      state.list
        .filter(id => state.submissions[id])
        .map(id => state.submissions[id])
        .filter(s => !s.is_public && !s.is_shared).length < 1
    ) {
      patchState({loading: true, loaded: false});
    }
    dispatch(new ReloadSubmissions());
  }

  @Action(ReloadSubmissions)
  reloadSubmissions(ctx: StateContext<SubmissionStateModel>): void {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    this.submissionsService.list(isAuthenticated).subscribe(
      submissions => ctx.dispatch(new LoadSubmissionsSuccess(submissions)),
      err => ctx.dispatch(new LoadFail(err))
    );
  }

  @Action(LoadSubmissionsSuccess)
  loadSubmissionsSuccess(ctx: StateContext<SubmissionStateModel>, action: LoadSubmissionsSuccess): void {
    ctx.patchState({
      loading: false,
      loaded: true,
    });
    if (!isEqual(action.submissions, ctx.getState().origin)) {
      this.store.dispatch(new UpdateSubmissions(action.submissions));
    }
  }

  @Action(LoadPrivateSubmissions)
  loadPrivateSubmissions(ctx: StateContext<SubmissionStateModel>): void {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    if (isAuthenticated) {
      this.submissionsService.getPrivateSubmissionsList().subscribe(
        submissions => ctx.dispatch(new LoadPrivateSubmissionsSuccess(submissions)),
        err => ctx.dispatch(new LoadFail(err))
      );
    }
  }

  @Action(LoadPrivateSubmissionsSuccess)
  loadPrivateSubmissionsSuccess(ctx: StateContext<SubmissionStateModel>, {privateSubmissions}: LoadPrivateSubmissionsSuccess): void {
    const submissions = {...ctx.getState().submissions};
    for (const submission of privateSubmissions) {
      submissions[submission.id] = submission;
    }

    ctx.patchState({
      privateSubmissionsLoaded: true,
      submissions,
    });
  }

  @Action(LoadPublicSubmissions)
  loadPublicSubmissions(ctx: StateContext<SubmissionStateModel>): void {
    this.submissionsService.getPublicSubmissionsList().subscribe({
      next: submissions => ctx.dispatch(new LoadPublicSubmissionsSuccess(submissions)),
      error: err => ctx.dispatch(new LoadFail(err)),
    });
  }

  @Action(LoadPublicSubmissionsSuccess)
  loadPublicSubmissionsSuccess(ctx: StateContext<SubmissionStateModel>, {publicSubmissions}: LoadPublicSubmissionsSuccess): void {
    const submissions = {...ctx.getState().submissions};
    for (const submission of publicSubmissions) {
      submissions[submission.id] = submission;
    }

    ctx.patchState({
      publicSubmissionsLoaded: true,
      submissions,
    });
  }

  @Action(LoadSubmission)
  loadSubmission(ctx: StateContext<SubmissionStateModel>, {submissionId, isPublic}: LoadSubmission): void {
    ctx.patchState({
      loadSubmissionProgress: PROGRESS_STATUSES.IN_PROGRESS,
      loading: true,
      loaded: false,
    });
    this.submissionsService.loadSubmissionDetails(submissionId, isPublic).subscribe({
      next: submission => this.store.dispatch(new LoadSubmissionSuccess(submission)),
      error: err => this.store.dispatch(new LoadSubmissionFail(err)),
    });
  }

  @Action(LoadSubmissionSuccess)
  loadSubmissionSuccess(ctx: StateContext<SubmissionStateModel>, action: LoadSubmissionSuccess): void {
    const state = ctx.getState();
    const submissions = state.submissions;
    const submission = action.submission;

    ctx.patchState({
      loadSubmissionProgress: PROGRESS_STATUSES.SUCCEED,
      loading: false,
      loaded: true,
      submissions: {
        ...submissions,
        [submission.id]: submission,
      },
    });

    const isVerified = this.store.selectSnapshot(AuthState.isVerified);

    if (isVerified || submission.is_public) {
      if (!submission.processed_image) {
        return; // image not exist yet (in processing) => there is no point in sending requests
      }
      ctx.dispatch(new LoadBrainAge());
    }
  }

  @Action(LoadSubmissionFail)
  loadSubmissionFail(ctx: StateContext<SubmissionStateModel>): void {
    ctx.patchState({
      loadSubmissionProgress: PROGRESS_STATUSES.INTERRUPTED,
      loading: true,
      loaded: false,
    });
  }

  @Action(LoadUserRelatedSubmissions)
  loadUserRelatedSubmissions(ctx: StateContext<SubmissionStateModel>, {userAccount}: LoadUserRelatedSubmissions): void {
    ctx.patchState({
      loading: true,
      loaded: false,
    });

    // Extract related submissions ID from user account data.
    const userClinicScans = userAccount.clinic_scans.map(scan => scan.id);

    // Form a request for each submission.
    const loadSubmissionsFunctions = userClinicScans.map(scanId => this.submissionsService.loadSubmissionDetails(+scanId, false));

    // Load all related submissions data simultaneously.
    forkJoin(loadSubmissionsFunctions).subscribe({
      next: submissions => ctx.dispatch(new LoadUserRelatedSubmissionsSuccess(submissions)),
      error: err => ctx.dispatch(new LoadUserRelatedSubmissionsFail(err)),
    });
  }

  @Action(LoadUserRelatedSubmissionsSuccess)
  loadUserRelatedSubmissionsSuccess(
    ctx: StateContext<SubmissionStateModel>,
    {relatedSubmissions}: LoadUserRelatedSubmissionsSuccess
  ): void {
    ctx.patchState({
      loading: false,
      loaded: true,
      list: relatedSubmissions.map(submission => submission.id),
      submissions: relatedSubmissions.reduce(
        (acc, submission) => ({...acc, [submission.id]: submission}),
        {} as {
          [key: string]: Submission;
        }
      ),
    });
  }

  @Action(LoadUserRelatedSubmissionsFail)
  loadUserRelatedSubmissionsFail(ctx: StateContext<SubmissionStateModel>, {error}: LoadUserRelatedSubmissionsFail): void {
    ctx.patchState({
      loading: false,
      loaded: true,
    });

    ctx.dispatch(new BackendError(error));
  }

  @Action(UpdateSubmissions)
  updateSubmissions(ctx: StateContext<SubmissionStateModel>, action: UpdateSubmissions): void {
    const list = [];
    const submissions = {...ctx.getState().submissions};

    let hasOwn = false;
    for (const submission of action.submissions) {
      list.push(submission.id);
      submissions[submission.id] = submission;

      if (!submission.is_public) {
        hasOwn = true;
      }
    }
    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      if (hasOwn && !this.submissionsService.hasUploaded()) {
        this.submissionsService.hasUploadedSave();
      }
      if (!hasOwn && this.submissionsService.hasUploaded()) {
        this.submissionsService.hasUploadedDelete();
      }
    }

    ctx.patchState({
      loading: false,
      loaded: true,
      initialized: true,
      origin: action.submissions,
      list,
      submissions,
    });

    const selected = ctx.getState().selected;
    if (!selected) {
      return;
    }

    const selectedSubmission = submissions[selected];
    const isLastLoadInterrupted = ctx.getState().loadSubmissionProgress === PROGRESS_STATUSES.INTERRUPTED;
    if (selectedSubmission) {
      // Do not reload if last load ends up with an error.
      if (!isLastLoadInterrupted) {
        this.store.dispatch(new LoadSubmission(selected, selectedSubmission.is_public));
      }
    } else {
      this.store.dispatch(new SelectSubmission(null, false));
    }
  }

  @Action(LoadFail)
  loadFail(ctx: StateContext<SubmissionStateModel>, action: LoadFail): void {
    ctx.patchState({
      loading: false,
      loaded: false,
      error: action.error,
    });
  }

  @Action(SaveSubmission)
  saveSubmission(ctx: StateContext<SubmissionStateModel>, {submission}: SaveSubmission): void {
    this.submissionsService.updateSubmission(submission).subscribe({
      next: () => ctx.dispatch(new SaveSubmissionSuccess(submission)),
      error: error => ctx.dispatch(new SaveSubmissionFail(error)),
    });
  }

  @Action(SaveSubmissionSuccess)
  saveSubmissionSuccess(ctx: StateContext<SubmissionStateModel>, {submission}: SaveSubmissionSuccess): void {
    const state = ctx.getState();
    const submissions = state.submissions;

    ctx.patchState({
      submissions: {
        ...submissions,
        [submission.id]: submission,
      },
    });
    ctx.dispatch(new SuccessMessage('Successfully updated scan details'));
  }

  @Action(SaveSubmissionFail)
  saveSubmissionFail(ctx: StateContext<SubmissionStateModel>, {error}: SaveSubmissionFail): void {
    ctx.dispatch([new ErrorMessage('Unable to update scan details'), new BackendError(error)]);
  }

  @Action(SelectSubmission)
  selectSubmission(ctx: StateContext<SubmissionStateModel>, action: SelectSubmission): void {
    const state = ctx.getState();
    const prevSelected = state.selected;
    const nextSelected = action.id;
    const nextIsPublic = action.isPublic;
    if (prevSelected && !nextSelected) {
      ctx.patchState({selected: null});
      ctx.dispatch(new SelectForSubmission(null));
      return;
    }

    if (prevSelected !== nextSelected) {
      const patch: {selected: string; submissions?: {[x: string]: Submission | {id: string}}} = {selected: nextSelected};
      if (!state.submissions[nextSelected]) {
        patch.submissions = {
          ...state.submissions,
          [nextSelected]: {id: nextSelected},
        };
      }
      ctx.patchState(patch);
    }

    if (action.isStatic) {
      ctx.patchState({isStaticSubmissionSelected: true});
    } else {
      ctx.patchState({isStaticSubmissionSelected: false});
    }

    if (nextSelected && !has(state, `submissions.${nextSelected}.chart_urls`) && !action.isStatic) {
      this.store.dispatch(new LoadSubmission(nextSelected, nextIsPublic));
    }
  }

  @Action(SelectMainLevelBrainPart)
  selectDashboardBrainPart(ctx: StateContext<SubmissionStateModel>): void {
    ctx.patchState({
      customViewerRegion: null,
    });
  }

  @Action(FocusCustomViewerRegion)
  focusCustomViewerRegion(ctx: StateContext<SubmissionStateModel>, {region}: FocusCustomViewerRegion): void {
    ctx.patchState({
      customViewerRegion: region,
    });
  }

  @Action(UpdateCustomViewerRegionList)
  updateCustomViewerRegionList(ctx: StateContext<SubmissionStateModel>, {regionList}: UpdateCustomViewerRegionList): void {
    ctx.patchState({
      customViewerRegionList: regionList,
    });
  }

  @Action(LoginSuccess)
  loginSuccess({patchState}: StateContext<SubmissionStateModel>): void {
    const submissions = {};

    patchState({
      selected: null,
      list: [],
      submissions,
      origin: null,
      brainAge: null,
    });
  }

  @Action(LogoutSuccess)
  logoutSuccess({patchState}: StateContext<SubmissionStateModel>): void {
    const submissions = {};

    patchState({
      selected: null,
      list: [],
      submissions,
      origin: null,
      brainAge: null,
      privateSubmissionsLoaded: false,
      publicSubmissionsLoaded: false,
    });
  }

  @Action(LoadBrainAge)
  loadBrainAge(ctx: StateContext<SubmissionStateModel>): void {
    const state = ctx.getState();
    if (state.selected && state.submissions[state.selected] && state.submissions[state.selected].brain_age_derivative) {
      this.submissionsService
        .getBrainAge(state.submissions[state.selected].brain_age_derivative, state.submissions[state.selected].is_public)
        .subscribe(
          brainAge => this.store.dispatch(new LoadBrainAgeSuccess(brainAge)),
          err => this.store.dispatch(new BackendError(err))
        );
    }
  }

  @Action(LoadBrainAgeSuccess)
  loadBrainAgeSuccess(ctx: StateContext<SubmissionStateModel>, action: LoadBrainAgeSuccess): void {
    ctx.patchState({
      brainAge: action.brainAge.brain_age,
    });
  }

  @Action(UpdateSingleSubmission)
  updateSingleSubmission(
    {dispatch, getState, patchState}: StateContext<SubmissionStateModel>,
    {submissionId, submission}: UpdateSingleSubmission
  ): void {
    patchState({
      updateSingleSubmissionProgress: {
        ...getState().updateSingleSubmissionProgress,
        [submissionId]: PROGRESS_STATUSES.IN_PROGRESS,
      },
    });

    this.submissionsService.updateSubmission(submission).subscribe({
      next: () => dispatch(new UpdateSingleSubmissionSuccess(submissionId, submission)),
      error: error => dispatch(new UpdateSingleSubmissionFail(submissionId, error)),
    });
  }

  @Action(UpdateSingleSubmissionSuccess)
  updateSingleSubmissionSuccess(
    {getState, patchState}: StateContext<SubmissionStateModel>,
    {submissionId}: UpdateSingleSubmissionSuccess
  ): void {
    patchState({
      updateSingleSubmissionProgress: {
        ...getState().updateSingleSubmissionProgress,
        [submissionId]: PROGRESS_STATUSES.SUCCEED,
      },
    });
  }

  @Action(UpdateSingleSubmissionFail)
  updateSingleSubmissionFail(
    {dispatch, getState, patchState}: StateContext<SubmissionStateModel>,
    {submissionId, error}: UpdateSingleSubmissionFail
  ): void {
    patchState({
      updateSingleSubmissionProgress: {
        ...getState().updateSingleSubmissionProgress,
        [submissionId]: PROGRESS_STATUSES.INTERRUPTED,
      },
    });

    dispatch(new BackendError(error));
  }
}
